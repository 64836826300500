/* ready */
$(function() {
  'use strict';

  /* スライダー */
  var mvSlider = $('.p-top-mv__slide');
  var slider = $('.p-top-results__list__main');
  var thumbnail = $('.p-top-results__list__thumb li');

  mvSlider.slick({
    fade: true,
    autoplay: true,
    arrows: false,
    speed: 2000,
    autoplaySpeed: 5000,
    pauseOnFocus: false,
    pauseOnHover: false
  });


  // 初期サムネイル
  thumbnail.eq(0).addClass('is-current');

  slider.slick({
    fade: true,
    autoplay: true,
    arrows: false,
    speed: 1000,
    autoplaySpeed: 3000,
  }).on('beforeChange',function(event, slick, currentSlide, nextSlide){
    thumbnail.removeClass('is-current');
    thumbnail.eq(nextSlide).addClass('is-current');
  });

  // スライダー操作
  thumbnail.on('click', function() {
    var index = $(thumbnail).index(this);

    slider.slick('slickGoTo', index);
  });
});


/* -- load -- */
$(window).on('load' , function() {
  $('.p-top-mv__ttl span').addClass('is-animate');
});
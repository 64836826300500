/* ready */
$(function() {
  'use strict';

  /* お知らせ */
  $('.l-sidebar__list dt').click(function() {
    $(this).toggleClass('is-active');
    $(this).next().stop().slideToggle();
  });

  $('.p-results__search__tax__ttl').click(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth < 768) {
      $(this).toggleClass('is-active');
      $(this).next().stop().slideToggle();
    }
  });


  /* 実績紹介, 販売マンション */
  // スライダー
  var slider = $('.p-results--single__slide__main, .p-mansion--single__visual__main');
  var thumbnail = $('.p-results--single__slide__thumb li, .p-mansion--single__visual__side');

  // 初期サムネイル
  thumbnail.eq(0).addClass('is-current');

  slider.slick({
    fade: true,
    autoplay: true,
    arrows: false,
    speed: 1000,
    autoplaySpeed: 3000,
  }).on('beforeChange',function(event, slick, currentSlide, nextSlide){
    thumbnail.removeClass('is-current');
    thumbnail.eq(nextSlide).addClass('is-current');
  });

  // スライダー操作
  thumbnail.on('click', function() {
    var index = $(thumbnail).index(this);

    slider.slick('slickGoTo', index);
  });


  /* CSR */
  $('.p-csr__list__thumb__cv').click(function() {
    $(this).hide().siblings('video').get(0).play();
  });
});
/* ready */
$(function() {
  'use strict';

  /* スムーススクロール */
  $('a[href^="#"]').on('click', function() {
    // スピード（ミリ秒）
    var speed = 300;
    // アンカーの値取得
    var href = $(this).attr('href');
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を座標で取得
    var position;
    if ($(this).parent().hasClass('l-pagetop')) {
      position = target.offset().top;
    } else {
      position = target.offset().top - $('.l-header').outerHeight();
    }
    // アニメーション
    $('body,html').not(':animated').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  naviCurrent($('.l-gnavi__list > li'));

  /* header */
  var fixedFlag = false;
  var menuSwitch = false;

  $('.l-gnavi__list > li').hover(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth > 1024) {
      if ($('.l-header--home').length) {
        if (!menuSwitch) {
          menuSwitch = true;
          if (!fixedFlag) {
            $('.l-header--home').addClass('is-fixed');
          }
        }
      }
      // mnavi
      $(this).addClass('is-mnavi');
      $(this).find('.l-mnavi').stop().slideDown(200);
    }
  }, function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth > 1024) {
      if ($('.l-header--home').length) {
        if (menuSwitch) {
          menuSwitch = false;
          if (!fixedFlag) {
            $('.l-header--home').removeClass('is-fixed');
          } else {
            $('.l-header--home').addClass('is-fixed');
          }
        }
      }
      // mnavi
      $(this).removeClass('is-mnavi');
      $(this).find('.l-mnavi').stop().slideUp(200);
    }
  });

  if ($(window).scrollTop() > 80) {
    if ($('.l-header--home').length) {
      $('.l-header--home').addClass('is-fixed');
      fixedFlag = true;
    }
  }


  /* sp-navi */
  $('.l-header__menu').click(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth < 1025) {
      $('.l-header__search-sp').removeClass('is-active');
      $('.l-header__search').slideUp();

      if (!$(this).hasClass('is-active')) {
        $(this).addClass('is-active');
        $('.l-gnavi').stop().slideDown();

        if ($('.l-header--home').length) {
          if (!menuSwitch) {
            menuSwitch = true;
            if (!fixedFlag) {
              $('.l-header--home').addClass('is-fixed');
            }
          }
        }
      } else {
        $(this).removeClass('is-active');
        $('.l-gnavi').stop().slideUp();

        if ($('.l-header--home').length) {
          if (menuSwitch) {
            menuSwitch = false;
            if (!fixedFlag) {
              $('.l-header--home').removeClass('is-fixed');
            }
          }
        }
      }
    }
  });

  $('.l-header__search-sp').click(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth < 1025) {
      $('.l-header__menu').removeClass('is-active');
      $('.l-gnavi').slideUp();

      if (!$(this).hasClass('is-active')) {
        $(this).addClass('is-active');
        $('.l-header__search').stop().slideDown();

        if ($('.l-header--home').length) {
          if (!menuSwitch) {
            menuSwitch = true;
            if (!fixedFlag) {
              $('.l-header--home').addClass('is-fixed');
            }
          }
        }
      } else {
        $(this).removeClass('is-active');
        $('.l-header__search').stop().slideUp();

        if ($('.l-header--home').length) {
          if (menuSwitch) {
            menuSwitch = false;
            if (!fixedFlag) {
              $('.l-header--home').removeClass('is-fixed');
            }
          }
        }
      }
    }
  });

  /* -- scroll -- */
  $(window).scroll(function() {
    var deviceWidth = window.innerWidth;

    // pagetop
    if ($(this).scrollTop() > 80) {
      $('.l-pagetop').stop().fadeIn();
    } else {
      $('.l-pagetop').stop().fadeOut();
    }

    // header
    if ($(this).scrollTop() > 80) {
      if ($('.l-header--home').length) {
        if (!fixedFlag) {
          fixedFlag = true;
          if (!menuSwitch) {
            $('.l-header--home').addClass('is-fixed');
          }
        }
      }
    } else {
      if ($('.l-header--home').length) {
        if (fixedFlag) {
          fixedFlag = false;
          if (!menuSwitch) {
            $('.l-header--home').removeClass('is-fixed');
          }
        }
      }
    }
  });


  /* -- resize -- */
  $(window).resize(function() {
    var deviceWidth = window.innerWidth;

    // reset
    if (deviceWidth > 1024) {
      $('.l-header__menu').removeClass('is-active');
      $('.l-gnavi').hide();
      $('.l-mnavi').hide();
      $('.l-header__search-sp').removeClass('is-active');
      $('.l-header__search').hide();
    }
  });


  /* -- reload -- */
  if (window.performance) {
    if (performance.navigation.type === 1) {
      if ($.cookie("loading")) {
        $.removeCookie("loading", { path: "/" });
      }
    }
  }

  // cookie
  if (!$.cookie("loading")) {
    $('.l-loading').css('display', 'block');
  }
});


/* -- load -- */
$(window).on('load' , function() {
  $('body').removeClass('is-preload');
  // cookie
  if (!$.cookie("loading")) {
    $('.l-loading').addClass('is-load');
    $.cookie("loading", "loading", { path: "/" });
  }
});


/* function */
// naviCurrent
function naviCurrent(naviElm) {
  const currentUrl = location.pathname + location.hash;

  naviElm.each(function() {
    let link = $(this).children('a').attr('href');
    var matchLink = new RegExp('^' + link);

    if (link == currentUrl || currentUrl.match(matchLink)) {
      $(this).addClass('is-current');
    }
  });
}

// fadeBlock
function fadeBlock(elm) {
  elm.each(function() {
    var basePosition = $(this).offset().top;
    var scrollPosition = $(window).scrollTop();
    var deviceHeight = $(window).height();
    if (scrollPosition > basePosition - deviceHeight * .7) {
      $(this).addClass('is-animated');
    }
  });
}
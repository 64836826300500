/* -- load -- */
$(window).on('load' , function() {
  // history_results
  var historyThumb = $('.p-history-results__era__list__thumb li');
  var historyMain = $('.p-history-results__era__list__main');

  historyThumb.find('figure').click(function() {
    $(this).parent('li').siblings().removeClass('is-current');
    $(this).parent('li').addClass('is-current');

    $(this).closest('div').next(historyMain).find('img').eq(0).attr('src', $(this).find('img').attr('src'));
    if ($(this).parent('li').next().find('img').attr('src')) {
      $(this).closest('div').next(historyMain).find('img').eq(1).attr('src', $(this).parent('li').next().find('img').attr('src'));
    } else {
      $(this).closest('div').next(historyMain).find('img').eq(1).attr('src', $(this).parent('li').siblings().first().find('img').attr('src'));
    }
  });
});